<template>
  <div>
    <div>
      <iframe src="/maps/map.html?mapType=climatology"
        style="position: relative; height: 91vh; width: 100%;" scrolling="no" frameborder="0"
      ></iframe>
    </div>
  </div>
</template>

<script>
// import KTCodePreview from "@/view/content/CodePreview.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
     
    };
  },
  components: {
    // KTCodePreview
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Monitoreo", route: "alert" },
      { title: "Monitoreo" }
    ]);
  }
};
</script>
